import { createSlice } from "@reduxjs/toolkit";
import { AssociatedMetersState } from "../../models/smartbit";
import { getAssociatedMeters } from "../thunks/associatedMeters";
import { Node } from "reactflow";

const initialState: AssociatedMetersState = {
  nodes: [],
  edges: [],
  sbNode: {} as Node<any, string | undefined>,
  loading: false,
  error: false,
  meters: [],
};

export const associatedMetersSlice = createSlice({
  name: "associatedMeters",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAssociatedMeters.pending, (state) => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(getAssociatedMeters.fulfilled, (state, { payload }) => {
      state.error = false;
      state.loading = false;
      state.edges = payload.edges;
      state.nodes = payload.nodes;
      state.sbNode = payload.sbNode;
      state.meters = payload.associatedMeters;
    });
    builder.addCase(getAssociatedMeters.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });
  },
});

export const {} = associatedMetersSlice.actions;
