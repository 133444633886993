import { Box, Skeleton } from "@enerbit/base";
import React from "react";

const ActionsSkeleton = () => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <Skeleton
        sx={{ width: "200px", height: "48px", borderRadius: "12px" }}
        variant='rectangular'
      />
      <Skeleton
        sx={{ width: "200px", height: "48px", borderRadius: "12px" }}
        variant='rectangular'
      />
      <Skeleton
        sx={{ width: "200px", height: "48px", borderRadius: "12px" }}
        variant='rectangular'
      />
    </Box>
  );
};

export default ActionsSkeleton;
