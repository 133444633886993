/* eslint-disable @typescript-eslint/no-explicit-any */
import { Edge, Node } from "reactflow";

export interface SmarbitDetailState {
  sbGeneralInfo: SBData;
  loading: boolean;
  error: boolean;
  currentPage: number;
  nextPage: number;
  totalPages: number;
  devices: Device[];
}

export interface DeviceSignalState {
  loading: boolean;
  error: boolean;
  signal: AdaptedSignalResponse[];
  operator: string;
}

export interface AssociatedMetersState {
  nodes: Node<any, string | undefined>[];
  sbNode: Node<any, string | undefined>;
  edges: Edge<any>[];
  loading: boolean;
  error: boolean;
  meters: AdaptedAssociatedMeters[];
}

export interface SignalCoverageState {
  loading: boolean;
  error: boolean;
}

export interface DeviceDetail {
  id: string;
  group_id: null;
  imei: string;
  mac_address: string;
  country_name: string;
  state_name: string;
  locality_name: string;
  organization_name: string;
  latitude: null;
  longitude: null;
  created_at: Date;
  is_active: boolean;
  name: string;
  fw_version: string;
  net: string;
  operator: string;
  ip: string;
}

export interface SBData {
  ip: string;
  imei: string;
  macAddress: string;
  fWVersion: string;
  operator: string;
  deviceName: string;
  iccid: string;
  status: DeviceConnectionState;
}

export interface AssociatedMeters {
  meter_id: string;
  meter_serial: string;
  meter_model: string;
  validated: boolean;
  instalation_date: string;
}

export interface AdaptedAssociatedMeters {
  meterSerial: string;
  meterId: string;
}

export interface ISignal {
  device_name: string;
  time_sender: string;
  tt: string;
  signal_status: string;
  operator: string;
  time_event: string;
  lost_packages: number;
  avg_time: number;
  total_time: number;
}

export interface AdaptedSignalResponse {
  signalStatus: number;
  date: string;
}

export enum RestoreSchedulesTypes {
  DEVICE = "device",
  METER = "meter",
}

export interface RestoreSchedulesOptions {
  name: string;
  value: RestoreSchedulesTypes;
}

export interface ListDeviceResponse {
  page: number;
  pages: number;
  size: number;
  total: number;
  next_page: null | number;
  previous_page: null | number;
  items: Device[];
}

export enum DeviceConnectionState {
  DISCONNECTED = "Disconnected",
  CONNECTED = "Connected",
}

export interface Device {
  device_id: string;
  device_name: string;
  last_activity_time: string;
  connection_state: DeviceConnectionState;
  operator: string;
  iccid: string;
  is_active: boolean;
}
