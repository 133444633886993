import { Box, CustomAlert } from "@enerbit/base";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import ReactFlow, { Controls, ReactFlowProvider } from "reactflow";

import "reactflow/dist/style.css";
import { CUSTOM_NODES } from "../../common/meterTree";

const MetersTree = () => {
  const reactFlowWrapper = useRef(null);

  const { edges, nodes, error } = useSelector((state: RootState) => state.associatedMeters);

  return (
    <ReactFlowProvider>
      <Box ref={reactFlowWrapper} sx={{ width: "100%", height: "342px" }}>
        {error ? (
          <CustomAlert
            text='Error cargando ramificación de medidores'
            severity='error'
            onClose={() => {}}
          />
        ) : (
          <ReactFlow
            zoomOnScroll={false}
            zoomOnPinch={false}
            nodes={nodes}
            edges={edges}
            nodeTypes={CUSTOM_NODES}
            fitView>
            <Controls />
          </ReactFlow>
        )}
      </Box>
    </ReactFlowProvider>
  );
};

export default MetersTree;
