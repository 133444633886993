import { CustomAlert, Grid, Typography, enerbitColors } from "@enerbit/base";
import CustomContainer from "../../../../components/CustomContainer";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { GENERAL_INFO } from "../../common/device";

const GeneralInformation = () => {
  const generalInfo = useSelector((state: RootState) => state.generalInfo.sbGeneralInfo);
  const error = useSelector((state: RootState) => state.generalInfo.error);

  return (
    <>
      {error ? (
        <CustomAlert
          text='Error cargando información general del smartBit'
          severity='error'
          onClose={() => {}}
        />
      ) : (
        <Grid container columnSpacing={2}>
          {GENERAL_INFO.map((item) => (
            <Grid item sm={12 / GENERAL_INFO.length} key={item.key}>
              <CustomContainer>
                <Typography sx={{ fontWeight: 800, color: enerbitColors.neutral[900] }}>
                  {generalInfo[item.key] ?? "Sin registro"}
                </Typography>
                <Typography sx={{ fontSize: "12px", color: enerbitColors.neutral.main }}>
                  {item.label}
                </Typography>
              </CustomContainer>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default GeneralInformation;
