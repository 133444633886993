import { PrivateRoute, ThemeConfig, initEnviroment } from "@enerbit/base";
import { hot } from "react-hot-loader/root";
import { store } from "./store/store";
import { Provider } from "react-redux";
import MainLayout from "./layout/MainLayout";
import Router from "./router/Router";

initEnviroment({
  baseUrl: process.env.REACT_APP_BASE_URL,
});

export default hot(function Root() {
  return (
    <PrivateRoute>
      <Provider store={store}>
        <ThemeConfig>
          <MainLayout>
            <Router />
          </MainLayout>
        </ThemeConfig>
      </Provider>
    </PrivateRoute>
  );
});
