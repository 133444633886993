import { ArrowForwardIcon, Box, Button, Skeleton, Typography, enerbitColors } from "@enerbit/base";
import { useNavigate, useParams } from "react-router-dom";
import StatusTag from "../../../../components/StatusTag";
import Actions from "./Actions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { DeviceConnectionState } from "../../../../models/smartbit";
import { DEVICE_CONNECTION_STATUS } from "../../../SmartbitsList/common";
import ActionsSkeleton from "./ActionsSkeleton";

const Header = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const generalInfo = useSelector((state: RootState) => state.generalInfo.sbGeneralInfo);
  const loading = useSelector((state: RootState) => state.generalInfo.loading);
  const error = useSelector((state: RootState) => state.generalInfo.error);
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "start" }}>
        <Box sx={{ display: "flex", alignItems: "start", gap: 1 }}>
          <Button
            sx={{
              minWidth: "unset !important",
              height: "41px !important",
              width: "41px !important",
              borderRadius: "50% !important",
            }}
            variant='outlined'
            onClick={() => {
              navigate("/");
            }}>
            <ArrowForwardIcon style={{ transform: "rotate(180deg)" }} />
          </Button>
          {!error && (
            <Box>
              <Typography
                sx={{ fontSize: "28px", fontWeight: 700, color: enerbitColors.primary.main }}>
                {id}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center !important", gap: 1, mt: 1.5 }}>
                {loading ? (
                  <Skeleton variant='text' sx={{ fontSize: "22px" }} />
                ) : (
                  <>
                    <Typography sx={{ color: enerbitColors.neutral[700] }}>Estado:</Typography>
                    <StatusTag
                      sx={{ width: "120px" }}
                      bgcolor={
                        DEVICE_CONNECTION_STATUS[generalInfo.status as DeviceConnectionState]
                          .bgcolor
                      }
                      fontcolor={
                        DEVICE_CONNECTION_STATUS[generalInfo.status as DeviceConnectionState]
                          .fontcolor
                      }>
                      {DEVICE_CONNECTION_STATUS[generalInfo.status as DeviceConnectionState].label}
                    </StatusTag>
                  </>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "right", mt: 2 }}>
        {loading ? <ActionsSkeleton /> : <Actions />}
      </Box>
    </>
  );
};

export default Header;
