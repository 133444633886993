import { enerbitColors } from "@enerbit/base";
import { DeviceConnectionState } from "../../../models/smartbit";

export const TABLE_HEADERS: string[] = [
  "Id",
  "ICCID",
  "Operador",
  "Última actividad",
  "Operación",
  "Estado conexión",
  "Detalle",
];

export const DEVICE_CONNECTION_STATUS: Record<
  DeviceConnectionState,
  { label: string; bgcolor: string; fontcolor: string }
> = {
  Disconnected: {
    label: "Desconectado",
    bgcolor: enerbitColors.error[100] || "",
    fontcolor: enerbitColors.error.main,
  },
  Connected: {
    label: "Conectado",
    bgcolor: enerbitColors.success[100] || "",
    fontcolor: enerbitColors.success.main,
  },
};

export const DEVICE_OPERATION_STATUS: Record<
  string,
  { label: string; bgcolor: string; fontcolor: string }
> = {
  inactive: {
    bgcolor: enerbitColors.warning[100] ?? "",
    fontcolor: enerbitColors.warning.main,
    label: "En inventario",
  },
  active: {
    bgcolor: enerbitColors.success[100] ?? "",
    fontcolor: enerbitColors.success.main,
    label: "Instalado",
  },
};
