import { createSlice } from "@reduxjs/toolkit";
import { EventsState } from "../../models/events";
import { getEvents } from "../thunks/sbEvents";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../common/constants";

const initialState: EventsState = {
  loading: false,
  error: false,
  currentPage: 0,
  nextPage: 0,
  totalPages: 0,
  events: [],
  since: moment().startOf("day").format(DATE_TIME_FORMAT),
  until: moment().endOf("day").format(DATE_TIME_FORMAT),
};

export const sbEventsSlice = createSlice({
  name: "sbEvents",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getEvents.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getEvents.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.events = payload.events;
      state.currentPage = payload.currentPage;
      state.totalPages = payload.totalPages;
    });
    builder.addCase(getEvents.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const {} = sbEventsSlice.actions;
