import { Box, Button, enerbitColors } from "@enerbit/base";
import React, { useState } from "react";
import { navigateToUrl } from "single-spa";
import RefustigationModal from "../RefustigationModal/RefustigationModal";
import RestoreSchedulesModal from "../RestoreSchedulesModal/RestoreSchedulesModal";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import BuildIcon from "@mui/icons-material/Build";
import ResetModal from "../ResetModal/ResetModal";

const Actions = () => {
  const [openRefustigation, setOpenRefustigation] = useState<boolean>(false);
  const [openRestoreSchedules, setOpenRestoreSchedules] = useState<boolean>(false);
  const [openReset, setOpenReset] = useState<boolean>(false);

  const goToOrderServices = () => {
    navigateToUrl("/#/orders");
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Button
          sx={{ width: "200px" }}
          variant='contained'
          color='secondary'
          startIcon={<BuildIcon />}
          onClick={goToOrderServices}>
          Generar OS
        </Button>
        <Button
          sx={{ width: "200px" }}
          variant='contained'
          color='primary'
          startIcon={<QueryStatsIcon />}
          onClick={() => setOpenRefustigation(true)}>
          Refustigar
        </Button>
        <Button
          sx={{ width: "200px", color: "#fff" }}
          variant='contained'
          color='success'
          startIcon={<CloudSyncIcon />}
          onClick={() => setOpenRestoreSchedules(true)}>
          Recuperar datos
        </Button>
        <Button
          sx={{ width: "200px", color: "#fff", backgroundColor: "rgba(247, 144, 9, 1)" }}
          startIcon={<RestartAltIcon />}
          variant='contained'
          onClick={() => setOpenReset(true)}>
          Reiniciar smartBit
        </Button>
      </Box>
      <RefustigationModal open={openRefustigation} onClose={() => setOpenRefustigation(false)} />
      <RestoreSchedulesModal
        open={openRestoreSchedules}
        onClose={() => setOpenRestoreSchedules(false)}
      />
      <ResetModal open={openReset} onClose={() => setOpenReset(false)} />
    </>
  );
};

export default Actions;
