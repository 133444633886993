/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Typography,
  enerbitColors,
  IconButton,
  CheckIcon,
  ContentCopyIcon,
  Tooltip,
} from "@enerbit/base";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Handle, NodeProps, Position } from "reactflow";
import { RootState } from "../../../../store/store";

export const SmartbitNode = ({ data }: NodeProps<string>) => {
  const { edges } = useSelector((state: RootState) => state.associatedMeters);

  const [copied, setCopied] = useState(false); // Nuevo estado para controlar el ícono

  const handleCopy = () => {
    navigator.clipboard.writeText(data);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Box
      sx={{
        width: "100px",
        backgroundColor: "#fff",
        borderRadius: "3px",
        border: "1px solid #53358E",
      }}>
      <Box
        sx={{
          width: "100%",
          padding: "5px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}>
        <Typography
          sx={{
            fontSize: "7px",
            color: enerbitColors.primary.main,
          }}>
          smartBit
        </Typography>
        <Tooltip title={copied ? "Copiado" : ""} arrow placement='top'>
          <IconButton sx={{ fontSize: "7px" }} size='small' onClick={handleCopy}>
            {copied ? (
              <CheckIcon sx={{ fontSize: "7px", color: enerbitColors.primary.main }} />
            ) : (
              <ContentCopyIcon sx={{ fontSize: "7px", color: enerbitColors.primary.main }} />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        sx={{
          backgroundColor: "#EFE9FB",
          padding: "5px",
          height: "35px",
          borderRadius: "0px 0px 3px 3px",
          textAlign: "center",
          overflow: "hidden",
        }}>
        <Typography color={"primary"} fontWeight={700} sx={{ fontSize: "8px" }}>
          {data}
        </Typography>
      </Box>
      {edges && edges?.length > 0 && <Handle type='source' position={Position.Top} id='a' />}
    </Box>
  );
};
