import { HashRouter, Route, Routes } from "react-router-dom";
import SmartbitsList from "../pages/SmartbitsList/SmartbitsList";
import SmartbitDetail from "../pages/SmartbitDetail/SmartbitDetail";

const Router = () => {
  return (
    <HashRouter basename='/smartbits'>
      <Routes>
        <Route path='/' element={<SmartbitsList />} />
        <Route path='/:id' element={<SmartbitDetail />} />
      </Routes>
    </HashRouter>
  );
};

export default Router;
