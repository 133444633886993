/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography, enerbitColors, IconButton, Tooltip, CheckIcon } from "@enerbit/base";
import { Handle, NodeProps, Position } from "reactflow";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useState } from "react";
import { AdaptedAssociatedMeters } from "../../../../models/smartbit";

export const MeterNode = ({ data }: NodeProps<AdaptedAssociatedMeters>) => {
  const { meterSerial } = data;
  const [copied, setCopied] = useState(false); // Nuevo estado para controlar el ícono

  const handleCopy = () => {
    navigator.clipboard.writeText(meterSerial);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Box
      sx={{
        width: "100px",
        border: "1px solid #E4E7EC",
        backgroundColor: "#fff",
        borderRadius: "3px",
      }}>
      <Box
        sx={{
          width: "100%",
          padding: "5px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}>
        <Typography
          sx={{
            fontSize: "7px",
          }}>
          Medidor
        </Typography>
        <Tooltip title={copied ? "Copiado" : ""} arrow placement='top'>
          <IconButton sx={{ fontSize: "7px" }} size='small' onClick={handleCopy}>
            {copied ? (
              <CheckIcon sx={{ fontSize: "7px", color: enerbitColors.primary.main }} />
            ) : (
              <ContentCopyIcon sx={{ fontSize: "7px", color: enerbitColors.primary.main }} />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        sx={{
          backgroundColor: "#E4E7EC",
          padding: "5px",
          height: "35px",
          borderRadius: "0px 0px 3px 3px",
          textAlign: "center",
          overflow: "hidden",
        }}>
        <Typography color={enerbitColors.primary.main} fontWeight={700} sx={{ fontSize: "12px" }}>
          {meterSerial}
        </Typography>
      </Box>
      <Handle type='target' position={Position.Left} id='b' />
    </Box>
  );
};
