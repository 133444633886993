import { createSlice } from "@reduxjs/toolkit";
import { DeviceConnectionState, SmarbitDetailState } from "../../models/smartbit";
import { getSmarbitDetail, listSmartBits, searchDevice } from "../thunks/generalInfo";

const initialState: SmarbitDetailState = {
  error: false,
  loading: false,
  sbGeneralInfo: {
    fWVersion: "",
    imei: "",
    ip: "",
    macAddress: "",
    operator: "",
    deviceName: "",
    iccid: "",
    status: DeviceConnectionState.CONNECTED,
  },
  currentPage: 0,
  nextPage: 0,
  totalPages: 0,
  devices: [],
};

export const generalInfoSlice = createSlice({
  name: "generalInfo",
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getSmarbitDetail.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getSmarbitDetail.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.sbGeneralInfo = payload;
    });
    builder.addCase(getSmarbitDetail.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(listSmartBits.pending, (state) => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(listSmartBits.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.devices = payload.devices;
      state.currentPage = payload.currentPage;
      state.totalPages = payload.totalPages;
    });
    builder.addCase(listSmartBits.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });
    builder.addCase(searchDevice.pending, (state) => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(searchDevice.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.devices = payload.devices;
      state.currentPage = 0;
      state.totalPages = 0;
    });
    builder.addCase(searchDevice.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });
  },
});

export const { setCurrentPage } = generalInfoSlice.actions;
