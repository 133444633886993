import { createSlice } from "@reduxjs/toolkit";
import { OrdersHistoryState } from "../../models/orders";
import { getOrders } from "../thunks/ordersHIstory";

const initialState: OrdersHistoryState = {
  loading: false,
  error: false,
  currentPage: 0,
  nextPage: 0,
  totalPages: 0,
  orders: [],
};

export const ordersHistorySilce = createSlice({
  name: "ordersHistory",
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getOrders.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getOrders.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.orders = payload.adaptedOrders;
      state.currentPage = payload.currentPage;
      state.totalPages = payload.totalPages;
    });
    builder.addCase(getOrders.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { setCurrentPage } = ordersHistorySilce.actions;
