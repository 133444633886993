import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdaptedAssociatedMeters, AssociatedMeters } from "../../models/smartbit";
import { api } from "@enerbit/base";
import { generateEdges, generateNodes, generateSmartBit } from "../../utils/metersTree";

export const getAssociatedMeters = createAsyncThunk(
  "associatedMeters/getAssociatedMeters",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const sbMeters = await api.get<AssociatedMeters[]>(
        `/verification/smartbits/associated-meters?device_id=${id}`
      );

      const associatedMeters: AdaptedAssociatedMeters[] = sbMeters.data.map((meter) => ({
        meterSerial: meter.meter_serial,
        meterId: meter.meter_id,
      }));

      const sbNode = generateSmartBit(id);
      const tempNodes = generateNodes(associatedMeters);
      const edges = generateEdges(id, tempNodes);
      const nodes = [...tempNodes, sbNode];

      return { nodes, edges, sbNode, associatedMeters };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
