import { api } from "@enerbit/base";

export const refutigateMeters = async (date: string, serials: string[]) => {
  const res = await api.post("/electricity-supply-service/device/meters/refustigate-days", {
    date,
    meter_serials: serials,
  });

  return res;
};

export const restoreSchedulesByMeter = async (meterId: string, since: string, until: string) => {
  const res = await api.post(
    `/electricity-supply-service/device/meters/${meterId}/restore-schedules/?since=${encodeURIComponent(
      since
    )}&until=${encodeURIComponent(until)}`
  );
  return res;
};

export const restoreSchedulesByDeviceName = async (
  deviceName: string,
  since: string,
  until: string
) => {
  const res = await api.post(
    `/electricity-supply-service/device/devices-name-iothub/${deviceName}/meters/restore-schedules/?since=${encodeURIComponent(
      since
    )}&until=${encodeURIComponent(until)}`
  );

  return res;
};

export const resetSmartbit = async (deviceName: string) => {
  const res = await api.post(`/electricity-supply-service/device/smartbits/${deviceName}/reset/`);

  return res;
};
