import { NodeTypes } from "reactflow";
import { MeterNode } from "../components/MetersTree/MeterNode";
import { SmartbitNode } from "../components/MetersTree/SmartbitNode";

export const radius = 300;
export const centralNodeWidth = 100;
export const spaceBetweenNodes = 50;
export const numberOfNodes = 16;

export const CUSTOM_NODES: NodeTypes = {
  smartBit: SmartbitNode,
  meter: MeterNode,
};
