/* eslint-disable @typescript-eslint/no-explicit-any */
import { Edge, Node } from "reactflow";
import { AdaptedAssociatedMeters } from "../models/smartbit";
import {
  numberOfNodes,
  radius,
  spaceBetweenNodes,
  centralNodeWidth,
} from "../pages/SmartbitDetail/common/meterTree";

export const generateEdges = (
  deviceNodeId: string,
  tempNodes: Node<any, string | undefined>[]
): Edge<any>[] =>
  tempNodes.map((item, index) => ({
    id: `e${index + 1}`,
    source: deviceNodeId,
    target: item.id,
    animated: true,
  }));

export const generateNodes = (nodes: AdaptedAssociatedMeters[]): Node<any, string | undefined>[] =>
  nodes.map((data, index: number) => {
    const angle = (2 * Math.PI * index) / numberOfNodes;
    const cosAngle = Math.cos(angle);
    const sinAngle = Math.sin(angle);
    return {
      id: data.meterSerial,
      type: "meter",
      data: data,
      position: {
        x: 300 + (radius + spaceBetweenNodes) * cosAngle - centralNodeWidth / 2,
        y: 300 + (radius + spaceBetweenNodes) * sinAngle - centralNodeWidth / 2,
      },
    };
  });

export const generateSmartBit = (id: string): Node<any, string | undefined> => ({
  id: id,
  type: "smartBit",
  data: id,
  position: {
    x: 300 - centralNodeWidth / 2,
    y: 300 - centralNodeWidth / 2,
  },
});
