import {
  ArrowForwardIcon,
  Button,
  CustomAlert,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  enerbitColors,
} from "@enerbit/base";
import { StyledTableHeader } from "../../../../components/StyledTable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import StatusTag from "../../../../components/StatusTag";
import moment from "moment";
import PaginationContainer from "../../../../components/PaginationContainer";
import { getOrders } from "../../../../store/thunks/ordersHIstory";
import { useParams } from "react-router-dom";
import { setCurrentPage } from "../../../../store/slices/ordersHistory";
import { ORDERS_HISTORY_TABLE_HEADERS, ORDER_STATUS_NAMES } from "../../common/orders";

const OrdersHistory = () => {
  const { id } = useParams();
  const { orders, currentPage, totalPages, error } = useSelector(
    (state: RootState) => state.ordersHistory
  );

  const dispatch = useDispatch<AppDispatch>();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (!id) return;
    event.preventDefault();
    console.log("value: ", value);
    dispatch(setCurrentPage(value - 1));
    dispatch(getOrders(id));
  };

  return (
    <>
      {error ? (
        <CustomAlert
          text='Error cargando historial de órdenes de servicio'
          severity='error'
          onClose={() => {}}
        />
      ) : (
        <>
          <TableContainer component={Paper} sx={{ boxShadow: "unset !important" }}>
            <Table>
              <TableHead>
                <StyledTableHeader>
                  {ORDERS_HISTORY_TABLE_HEADERS.map((item, index) => (
                    <TableCell key={`${index + 1}-${item}`}>{item}</TableCell>
                  ))}
                </StyledTableHeader>
              </TableHead>
              <TableBody>
                {orders.map((order) => (
                  <TableRow
                    key={order.id}
                    sx={{
                      "& .MuiTableCell-root": {
                        borderBottom: "unset !important",
                        padding: "7px !important",
                      },
                    }}>
                    <TableCell>{order.name}</TableCell>
                    <TableCell>
                      <StatusTag
                        fontcolor={ORDER_STATUS_NAMES[order.status].fontcolor}
                        bgcolor={ORDER_STATUS_NAMES[order.status].bgcolor}>
                        {ORDER_STATUS_NAMES[order.status].label}
                      </StatusTag>
                    </TableCell>
                    <TableCell>
                      {moment(order.date).utcOffset("-05:00").format("YYYY-MM-DD")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <PaginationContainer>
            <Stack spacing={2}>
              <Pagination
                page={currentPage + 1}
                count={totalPages}
                shape='rounded'
                onChange={handleChange}
              />
            </Stack>
          </PaginationContainer>
        </>
      )}
    </>
  );
};

export default OrdersHistory;
