import { styled, tooltipClasses, TooltipProps, Tooltip } from "@enerbit/base";

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#5c5b5e",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#5c5b5e",
  },
}));
