import { Box } from "@enerbit/base";
import { useState } from "react";
import { StyledTabs } from "../../../../components/StyledTabs";
import { StyledTab } from "../../../../components/StyledTab";
import { TabPanel } from "../../../../components/TabPanel";
import OrdersHistory from "./OrdersHistory";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import OrdersHistorySkeleton from "../../../../components/TableSekeleton";
import TableSkeleton from "../../../../components/TableSekeleton";
import Events from "./Events";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DetailTabs = () => {
  const [value, setValue] = useState<number>(0);
  const loadingOrdersHistory = useSelector((state: RootState) => state.ordersHistory.loading);
  const errorOrdersHistory = useSelector((state: RootState) => state.ordersHistory.error);
  const loadingEvents = useSelector((state: RootState) => state.sbEvents.loading);
  const errorEvents = useSelector((state: RootState) => state.sbEvents.error);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <StyledTabs value={value} onChange={handleChange}>
          <StyledTab label={<Box>Historial de órdenes de servicio</Box>} {...a11yProps(0)} />
          <StyledTab label={<Box>Eventos</Box>} {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box mt={3} pb={3}>
          {loadingOrdersHistory ? <TableSkeleton columns={4} rows={3} /> : <OrdersHistory />}
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box mt={3} pb={3}>
          {loadingEvents ? <TableSkeleton columns={3} rows={3} /> : <Events />}
        </Box>
      </TabPanel>
    </Box>
  );
};

export default DetailTabs;
