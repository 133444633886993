import {
  Box,
  Button,
  CloseIcon,
  CustomAlert,
  enerbitColors,
  Grid,
  LoadingButton,
  Modal,
  Typography,
} from "@enerbit/base";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { resetSmartbit } from "../../../../services/device";

interface Props {
  onClose: () => void;
  open: boolean;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "550px",
  bgcolor: "background.paper",
  p: 3,
  borderRadius: "10px",
};

const ResetModal = ({ onClose, open }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { sbGeneralInfo } = useSelector((state: RootState) => state.generalInfo);

  const onConfirmReset = async () => {
    setError(false);
    setSuccess(false);
    setLoading(true);

    try {
      await resetSmartbit(sbGeneralInfo.deviceName);
      setSuccess(true);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
          <Button onClick={onClose} variant='outlined' className='back-btn'>
            <CloseIcon />
          </Button>
        </Box>
        <Box mt={1}>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "24px",
              color: enerbitColors.primary.main,
              mb: 2,
            }}>
            ¿Estás seguro de reiniciar este smartBit?
          </Typography>
          {/* <Typography sx={{ color: "#667085", textAlign: "center", mt: 2 }}>
            Ten en cuenta que una vez reiniciado, el dispositivo volverá a sus configuraciones
            predeterminadas.
          </Typography> */}
          {error && (
            <CustomAlert
              sx={{ my: 2 }}
              text='Se presentó un error al reinciar el smartBit.'
              severity='error'
              onClose={() => setError(false)}
            />
          )}
          {success && (
            <CustomAlert
              sx={{ my: 2 }}
              text='Comando de reinicio enviado exitosamente.'
              severity='success'
              onClose={() => setSuccess(false)}
            />
          )}
          <Grid container columnSpacing={2} mt={2}>
            <Grid item xs={6} md={6}>
              <Button fullWidth variant='outlined' color='secondary' onClick={onClose}>
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6} md={6}>
              <LoadingButton
                fullWidth
                loading={loading}
                onClick={onConfirmReset}
                variant='contained'
                color='secondary'>
                Reiniciar
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default ResetModal;
