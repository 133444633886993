import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "@enerbit/base";
import { AdaptedOrders, GetOrdersResponse } from "../../models/orders";
import { RootState } from "../store";

export const getOrders = createAsyncThunk(
  "ordersHistory/getOrders",
  async (id: string, { rejectWithValue, dispatch, getState }) => {
    const state = getState() as RootState;
    const { currentPage } = state.ordersHistory;
    try {
      const orders = await api.get<GetOrdersResponse>(
        `/service-orders/orders?assignee_id=${id}&page=${currentPage}&size=15`
      );

      const adaptedOrders: AdaptedOrders[] = orders.data.items.map((item) => ({
        id: item.id,
        name: item.order_type.name,
        date: item.created_at,
        status: item.status,
      }));
      return { adaptedOrders, currentPage: orders.data.page, totalPages: orders.data.pages };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
