import { styled } from "@enerbit/base";
import { Box } from "@mui/system";

interface Props {
  fontcolor: string;
  bgcolor: string;
}

const StatusTag = styled(Box)<Props>(({ fontcolor, bgcolor }) => ({
  width: "100%",
  padding: "4px 16px 4px 16px",
  backgroundColor: bgcolor,
  color: fontcolor,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
}));

export default StatusTag;
